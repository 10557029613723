<template>
      <b-row>
        <b-col :class="is_detail_network ? 'col-12 col-xl-6' : 'col-12 col-lg-6'">
          <b-card :class="is_detail_network ? 'border-box-analytics' : ''">
            <b-card-header>
              <b-card-title>
                {{$t('instagram.mainCities')}} 
              </b-card-title>
            </b-card-header>
            <b-card-body v-if="audienceData.cities.length != 0">
              <component :is="apex_charts" class="cursor-pointer" type="bar" height="300" :options="chartOptions.city" :series="series.city">
                <template #series(name)="data">{{data}}</template>
              </component>
            </b-card-body>
            <no-results v-else :title="$t('creator.noData')" size_avatar="180px" :img_general="require('@/assets/images/no_graffics.svg') " :show_button="false"/>
          </b-card>
        </b-col>
        <b-col :class="is_detail_network ? 'col-12 col-xl-6' : 'col-12 col-lg-6'">
          <b-card :class="is_detail_network ? 'border-box-analytics' : ''">
            <b-card-header>
              <b-card-title>
                {{$t('instagram.mainCountries')}}
              </b-card-title>
            </b-card-header>
            <b-card-body v-if="audienceData.countries.length != 0">
              <component :is="apex_charts" class="cursor-pointer" type="bar" height="300" :options="chartOptions.country" :series="series.country"></component>
            </b-card-body>
            <no-results v-else :title="$t('creator.noData')" size_avatar="180px" :img_general="require('@/assets/images/no_graffics.svg') " :show_button="false"/>
          </b-card>
        </b-col>
      </b-row>
</template>
<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody } from 'bootstrap-vue'
import { decimalFixed } from '@/libs/utils/formats'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue')
  },
  props: {
    audienceData: {
      required: true
    },
    is_detail_network: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chartOptions: {
        country: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: '#4be3ac',
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: this.getCategories(this.audienceData.countries, 'name'),
            show: true,
            labels: {
              formatter: (val) => {
                return `${val}%`
              }
            }
          },
          tooltip: {
            y: {
              formatter: (val) => {
                return `${val}%`
              }
            }
          }
        },
        city: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: '#4be3ac',
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: this.getCategories(this.audienceData.cities, 'name'),
            show: true,
            labels: {
              formatter: (val) => {
                return `${decimalFixed(val, 2)}%`
              }
            }
          },
          tooltip: {
            y: {
              formatter: (val) => {
                return `${val}%`
              }
            }
          }
        },
      },
      series: {
        country: [{name: this.$t('instagram.porcent'),data: this.getSeries(this.audienceData.countries)}],
        city: [{name: this.$t('instagram.porcent'),data: this.getSeries(this.audienceData.cities)}],
      },
      apex_charts: null,
    }
  },
  methods: {
    getSeries (data) {
      return data.map(item => {
        return item.value
      })
    },
    getCategories (data, key) {
      return data.map(item => {
        return item[key]
      })
    },
  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
  },
  watch: {
    '$i18n.locale'() {
      this.series.country = [{name: this.$t('instagram.porcent'),data: this.getSeries(this.audienceData.countries)}]
      this.series.city = [{name: this.$t('instagram.porcent'),data: this.getSeries(this.audienceData.cities)}]
    }
  },
}
</script>
